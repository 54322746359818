html * {
    font-family: 'Roboto', sans-serif;
}
body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#root {
    height: 100%;
    display: flex;
    flex-direction: column;
}
