.bread-crumbs__button-container {
    position: absolute;
    right: 32px;
    top: 9px;
    height: 100%;
}


@media only screen and (max-width: 768px) {
    .bread-crumbs__button-container {
        right: 12px;
    }
}