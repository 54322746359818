@import '../../variables.css';

#user-avatar {
    margin-left: 10px;
    cursor: pointer;
    background-color: #2196F3;
    color: var(--white);
    width: 32px;
    height: 32px;
    font-size: 0.875rem;
}

#user-menu {
    margin-top: 5px;
}

#user-menu .user-menu__icon {
    margin-right: 0.5rem;
}