@import '../../variables.css';

.top-bar__wrapper {
    padding: 5px 15px 15px 5px;
    height: 60px;
    justify-content: space-between;
    align-items: center;
}

.top-bar__logo {
    height: 30px;
}

.top-bar__logo img {
    max-height: 100%;
}

/* styles for mobile browsers smaller than 480px */
@media only screen and (max-width: 768px) {
    .top-bar__logo{
        display: none;
    }

    .top-bar__company-selector-wrapper {
        justify-content: center;
    }
}

/* default iPad screens */
@media only screen and (device-width: 768px) {
}

/* Stolen from https://stackoverflow.com/questions/13550541/media-min-width-max-width */

