.modal__subheader, .modal__subheader--grey {
    font-size: 1.063rem;
    font-weight: 600;
    margin: 0.25rem 0 .8rem;
    color: var(--text-header);
    border-color: var(--text-header);
}

.modal__subheader--grey {
    color: var(--light-gray-text);
    border-color: var(--light-gray);
    padding-bottom: 0.5rem;
}

.modal__subheader.bottom-border, .modal__subheader--grey.bottom-border {
    border-bottom-width: 1px;
    border-bottom-style: solid;
}

.modal__input-group {
    display: flex;
    flex-direction: row;
    margin: 0;
    font-size: 0.875rem;
    font-weight: 400;
    color: var(--text-placeholder);
    padding-bottom: 0.25rem;
}

.modal__input-group__label {
    margin-top: .6rem;
    margin-bottom: .5rem;
    font-weight: normal;
}

.error-text {
    margin-bottom: .5rem;
    margin-top: 0;
    font-weight: normal;
    color: var(--red);
}

.enum-option {
    text-transform: capitalize;
}

.modal__section {
    margin-bottom: 2rem;
}

.modal__body {
    font-size: 0.875rem;
    font-weight: 400;
    color: var(--text-placeholder);
    margin: 0.75rem 0;
}

.modal__footer .button-row {
    justify-content: space-between;
}