@import './variables.css';

.drawer-menu__button > .toggle-sidebar-btn {
    position: relative;
    left: 4px;
    top: 7px;
}

.toggle-sidebar-btn > svg {
    font-size: 1.5em;
    color: var(--azure);
}

.main-wrapper {
    flex: 1;
}

.main-wrapper > * {
    flex-grow: 1;
}

.main-wrapper:has(> div.report-container) {
    padding: 0.625rem 1.25rem 0;
}

.breadcrumbs-wrapper {
    position: relative;
    margin-left: 0;
    margin-right: 0;
    padding: 20px 20px 0;
    text-transform: capitalize;
}

.mobile-previous-page {
    position: relative;
    display: none;
    padding: 20px 20px 0;
    text-transform: capitalize;
    font-size: 14px;
}

.mobile-previous-page > span {
    cursor: pointer;
    text-decoration: underline;
}

@media only screen and (max-width: 768px) {
    .breadcrumbs-wrapper {
        display: none;
    }

    .mobile-previous-page {
        padding-top: 12px;
        display: flex;
        align-items: center;
    }
}
