.top-bar__company-selector-wrapper {
    display:flex;
    justify-content: flex-end;
    flex-grow: 1;
}

.company-selector__menu-item .company-selector__menu-item-icon {
    margin-left: 0.5rem;
    visibility: hidden;
}

.company-selector__menu-item .company-selector__menu-item-icon.company-selector__menu-item-icon--is-default {
    visibility: initial;
    color: #2196F3;
}

.company-selector__menu-item:hover .company-selector__menu-item-icon {
    visibility: initial;
}

@media only screen and (min-width: 321px) and (max-width: 361px) {
    .top-bar__company-selector-label,
    .top-bar__company-selector{
        max-width: 200px;
    }
}

@media only screen and (max-width: 320px) {
    .top-bar__company-selector-label,
    .top-bar__company-selector{
        max-width: 150px;
    }
}