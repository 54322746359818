@import '../variables.css';

nav.side-menu ul {
    background: var(--white);
    list-style: none;
    padding-left: 0;
}

nav.side-menu ul li {
    line-height: 50px;
}

nav.side-menu ul li a {
    color: var(--dark-gray);
    border-left: 3px solid transparent;
    display: block;
    font-size: 15px;
    font-weight: 500;
    padding-left: 14px;
    text-decoration: none;
}

nav.side-menu ul li a.active {
    background-color: #eaefff;
    color:#2d5bff;
    border-left: 3px solid #2d5bff;
    margin-left: -3px;
}

nav.side-menu li {
    border-left: 3px solid transparent;
}

nav.side-menu ul li:not(.active):hover {
    background: var(--light-blue);
    border-left: 3px solid var(--azure);
}

.side-bar__logo {
    text-align: center;
    margin: 15px 0 5px;
}

.side-bar__logo img {
    height: 45px;
    pointer-events: none;
}