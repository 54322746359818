:root {
    /* COLORS */
    --white: #ffffff;
    --orange: #ffa62b;
    --yellow: #ffdb4d;
    --red: #ea5455;
    --azure: #4285f4;
    --light-blue: #eaeeff;
    --light-gray: #dadada;
    --dark-gray: #6c757d;
    --light-gray-text: #ACB0B5;
    --text-header: #495057;
    --text-placeholder: #6c757d;
    --body-bg: var(--white);
    --green: #22c55e;
}
