@import './variables.css';

html,
body {
    height: 100%;
}

body {
    margin: 0;
    padding: 0;
    background-color: var(--body-bg);
}

.App {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 0;
}

main {
    padding: 20px;
    display: flex;
    flex-grow: 1;
}

iframe {
    border: none;
}

@media only screen and (max-width: 768px) {
    .App {
        padding: 0rem 0.25rem;
    }
}

.green {
    color: var(--green);
}
.red {
    color: var(--red);
}
.blue {
    color: var(--azure);
}
.yellow {
    color: var(--yellow);
}
.orange {
    color: var(--orange);
}

.with-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}